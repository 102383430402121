import React from "react";
import QRCode from "react-qr-code";

function QR({ questionObj }) {
  const { answer } = questionObj;
  return (
    <div>
      <p>
        Thank you registering to receive your COVID-19 vaccine. You will receive
        an e-mail confirmation noting the location, date and time of your
        appointment along with a QR code.
      </p>

      <p>
        Please arrive within your scheduled appointment time to ensure the best
        experience possible.
      </p>

      <p>
        Please bring your driver's license or other photo identification, your
        insurance card (if applicable) and your QR code or e-mail confirmation
        (this can be printed or displayed from a mobile device upon check-in).
      </p>

      <p>
        Due to the limited quantity of vaccines available, only individuals that
        have pre-registered will receive a COVID-19 vaccine.
      </p>
      <QRCode value={answer} />
    </div>
  );
}

export { QR };
