import React from "react";

/**
 * Checkbox component
 * @param {string} name
 * @param {string} label
 * @returns {JSX.Element}
 * @constructor
 */
function Checkbox({ name, label }) {
  const props = arguments[0];
  return (
    <div className="form-check">
      <input
        className="form-check-input"
        type="radio"
        name={name}
        id={name}
        {...props}
      />
      <label className="form-check-label" htmlFor={name}>
        {label}
      </label>
    </div>
  );
}

export { Checkbox };
