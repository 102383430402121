import React, { useState, useEffect } from "react";
import { Button } from "../ui";

const emptyFunction = () => {};

function FirstOrSecondDose({
  questionObj,
  children,
  updateState = emptyFunction,
}) {
  const { uuid, options } = questionObj;
  const [answer, setAnswer] = useState({ answer: null, uuid });

  useEffect(() => {
    updateState(answer);
  }, [answer]);

  // useEffect(() => {
  //   if (answer.answer !== "second") {
  //     updateState(answer);
  //   }
  // }, [answer]);

  const updateLocalState = (answer) => {
    const nextQuestionUuid = options[answer];
    setAnswer({ uuid: nextQuestionUuid, answer });
  };

  return (
    <>
      <div>{children}</div>
      <div className="mt-4">
        <div className="row">
          <div className="col-12 col-lg-6 mb-2 mb-lg-0">
            <Button onClick={() => updateState && updateLocalState("first")}>
              First
            </Button>
          </div>

          <div className="col-12 col-lg-6">
            <Button onClick={() => updateState && updateLocalState("second")}>
              Second
            </Button>
          </div>
        </div>
      </div>
      {answer.answer === "second" && (
        <>
          <div className="mt-4">
            <p>Which vaccine was your first dose?</p>

            <div className="alert alert-warning mb-0 py-1 px-2">
              <small>
                If you don't know, please refer to your Vaccination Card from
                your first dose.
              </small>
            </div>
          </div>
          <div className="mt-4">
            <div className="row">
              <div className="col-12 col-lg-6">
                <Button
                  onClick={() => updateState && updateLocalState("moderna")}
                >
                  Moderna mRNA-1273
                </Button>
              </div>
              <div className="col-12 col-lg-6">
                <Button
                  onClick={() => updateState && updateLocalState("pfizer")}
                >
                  Pfizer-BioNTech
                </Button>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
}

export { FirstOrSecondDose };
