import React from "react";
import { Button } from "../ui";

const emptyFunction = () => {};

function Link({ children, updateState = emptyFunction }) {
  return (
    <div className="p-4 alert alert-warning text-center">
      <div className="lead">{children}</div>
      <div className="mt-4">
        <div className="row">
          <div className="col-12 col-lg-6 mx-auto d-flex items-center justify-center">
            <Button
              onClick={() =>
                updateState && updateState({ uuid: "welcome", answer: null })
              }
            >
              Return to Home
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
}

export { Link };
