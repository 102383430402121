import React, { useState, useEffect } from "react";
import { Button } from "../ui";

const emptyFunction = () => {};

function YesOrNo({ questionObj, children, updateState = emptyFunction }) {
  const { uuid, options } = questionObj;
  const [answer, setAnswer] = useState({ answer: null, uuid });

  useEffect(() => {
    updateState(answer);
  }, [answer]);

  const updateLocalState = (answer) => {
    const nextQuestionUuid = options[answer];
    setAnswer({ uuid: nextQuestionUuid, answer });
  };

  return (
    <>
      <div>{children}</div>
      <div className="mt-4">
        <div className="row">
          <div className="col-12 col-lg-6 mb-2 mb-lg-0">
            <Button onClick={() => updateState && updateLocalState("yes")}>
              Yes
            </Button>
          </div>

          <div className="col-12 col-lg-6">
            <Button onClick={() => updateState && updateLocalState("no")}>
              No
            </Button>
          </div>
        </div>
      </div>
    </>
  );
}

export { YesOrNo };
