import React from "react";

function Button(props) {
  return (
    <button
      className="btn btn-brand-blue btn-block text-light text-uppercase"
      {...props}
    >
      {props.children}
    </button>
  );
}

export { Button };
