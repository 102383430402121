import React from "react";
import ReactDOM from "react-dom";
import firebase from "firebase";
import { FirestoreProvider } from "@react-firebase/firestore";
import "./index.css";
import { config } from "./firebase-config";
import { QuestionnaireForm } from "./questionnaire";

ReactDOM.render(
  <React.StrictMode>
    <div className="container py-4 border">
      <header className="p-4 bg-light">
        <div className="container">
          <div className="row">
            <div className="d-flex w-100 justify-content-between align-items-center">
              <div className="row w-100">
                <div className="col-12 col-lg-4 d-flex align-items-center">
                  {/*<h1 className="mb-0">Questionnaire</h1>*/}
                </div>

                <div className="col-12 col-lg-8 mx-auto d-flex justify-content-end align-items-center">
                  <div className="row">
                    <div className="col-12 col-lg-6 d-flex align-items-center justify-content-center">
                      <img
                        className="mr-lg-3"
                        style={{
                          maxWidth: "8rem",
                        }}
                        src="/county-seal.png"
                        alt="Montgomery County Seal"
                      />
                      <img
                        className="mr-lg-3 d-block"
                        style={{ maxWidth: "8rem", height: "auto" }}
                        src="/mcohsem-logo.png"
                        alt=""
                      />
                    </div>

                    <div className="col-12 col-lg-6 d-flex align-items-center justify-content-center">
                      <img
                        className="d-block"
                        style={{ maxWidth: "17rem", height: "auto" }}
                        src="https://www.lonestarfamily.org/wp-content/uploads/2020/07/logo-blue-red.png"
                        alt="Lone Start Health Family logo"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>
      <main className="py-4">
        <div className="container">
          <div className="row">
            <div className="w-100 p-4 border">
              <QuestionnaireForm />
              {/*<p className="h3 mb-0">*/}
              {/*  We no longer have appointments available for this week (Jan. 18*/}
              {/*  - Jan. 24). We will update this website as soon as we receive*/}
              {/*  our next allotments from the Texas Department of State Health*/}
              {/*  Services.*/}
              {/*</p>*/}
              {/*<h2 className="text-uppercase">Coming Soon</h2>*/}

              {/*<p className="lead">*/}
              {/*  Lone Star Family Health Center received notification from the*/}
              {/*  Texas Department of State Health Services that we will be*/}
              {/*  receiving 2,000 doses of the Moderna COVID-19 this week. At this*/}
              {/*  time, vaccines have not been received, but we are working*/}
              {/*  diligently with local officials to begin mass vaccination*/}
              {/*  efforts this week for eligible individuals.*/}
              {/*</p>*/}
              {/*<p className="lead">*/}
              {/*  We anticipate that within 24 hours, you will be able to schedule*/}
              {/*  your vaccine appointment online by visiting{" "}*/}
              {/*  <a href="https://lonestarvaccine.com">*/}
              {/*    www.lonestarvaccine.com*/}
              {/*  </a>*/}
              {/*  .*/}
              {/*</p>*/}

              {/*<p className="lead">*/}
              {/*  Please follow our website, Facebook and Twitter pages for*/}
              {/*  important announcements related to this effort. Thank you for*/}
              {/*  your patience.*/}
              {/*</p>*/}
            </div>
          </div>
        </div>
      </main>
      <footer className="py-4">
        <div className="container">
          <div className="row">&copy; 2021</div>
        </div>
      </footer>
    </div>

    {/*<FirestoreProvider {...config} firebase={firebase}>*/}
    {/*  <div className="container py-4 border">*/}
    {/*    <header className="p-4 bg-light">*/}
    {/*      <div className="container">*/}
    {/*        <div className="row">*/}
    {/*          <div className="d-flex w-100 justify-content-between align-items-center">*/}
    {/*            <div className="row w-100">*/}
    {/*              <div className="col-4 d-flex align-items-center">*/}
    {/*                <h1 className="mb-0">Questionnaire</h1>*/}
    {/*              </div>*/}

    {/*              <div className="col-8 d-flex justify-content-end align-items-center">*/}
    {/*                <img*/}
    {/*                  className="mr-3"*/}
    {/*                  style={{*/}
    {/*                    maxWidth: "8rem",*/}
    {/*                  }}*/}
    {/*                  src="/county-seal.png"*/}
    {/*                  alt="Montgomery County Seal"*/}
    {/*                />*/}
    {/*                <img*/}
    {/*                  className="mr-3"*/}
    {/*                  style={{ maxWidth: "8rem" }}*/}
    {/*                  src="/mcohsem-logo.png"*/}
    {/*                  alt=""*/}
    {/*                />*/}
    {/*                <img*/}
    {/*                  style={{ maxWidth: "17rem" }}*/}
    {/*                  src="https://www.lonestarfamily.org/wp-content/uploads/2020/07/logo-blue-red.png"*/}
    {/*                  alt="Lone Start Health Family logo"*/}
    {/*                />*/}
    {/*              </div>*/}
    {/*            </div>*/}
    {/*          </div>*/}
    {/*        </div>*/}
    {/*      </div>*/}
    {/*    </header>*/}
    {/*    <main className="py-4">*/}
    {/*      <div className="container">*/}
    {/*        <div className="row">*/}
    {/*          <QuestionnaireForm />*/}
    {/*        </div>*/}
    {/*      </div>*/}
    {/*    </main>*/}
    {/*    <footer className="py-4">*/}
    {/*      <div className="container">*/}
    {/*        <div className="row">&copy; 2021</div>*/}
    {/*      </div>*/}
    {/*    </footer>*/}
    {/*  </div>*/}
    {/*</FirestoreProvider>*/}
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
