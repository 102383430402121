import React, { useState } from "react";
import map from "lodash.map";
import { v4 as uuidv4 } from "uuid";
import axios from "axios";
import { useForm } from "react-hook-form";
import FA from "react-fontawesome";
import { Button } from "../../ui";
import { stateObj } from "./field-names";
import { Input } from "./input";
import { FirestoreCollection } from "@react-firebase/firestore";

const FormHeading = ({ children }) => (
  <div className="row mt-4 pt-4 mb-2">
    <div className="col">
      <p className="lead font-weight-bold">{children}</p>
    </div>
  </div>
);

function PatientInformationForm({ updateState }) {
  const { register, handleSubmit, watch, errors } = useForm();
  const [patientInfo, setPatientInfo] = useState({
    ...stateObj,
    uuid: { label: "uuid", value: uuidv4() },
  });
  const [appointment, setApointment] = useState({
    locationName: "",
    appointmentDate: "",
    appointmentTime: "",
  });
  const [isLoading, setLoading] = useState(false);

  console.log("watch", watch("example"));
  console.log("errors", errors);
  console.log("type of register", typeof register);

  const handleChange = (event) => {
    const { target } = event;
    const { name } = target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    // Need to generate
    //   firstName: {
    //     label: "First Name",
    //     value: "John Doe",
    //   }
    const fieldObj = {
      [name]: {
        ...patientInfo[name],
        value,
      },
    };

    setPatientInfo(
      Object.assign(
        {},
        {
          ...patientInfo,
          ...fieldObj,
        }
      )
    );
  };

  async function onSubmit(data, event) {
    event.preventDefault();
    setLoading(true);

    const response = await axios.post(
      "https://us-central1-lone-star-covid-questionnaire.cloudfunctions.net/addPatient",
      // "http://localhost:5001/lone-star-covid-questionnaire/us-central1/addPatient",
      {
        ...patientInfo,
        appointmentDate: {
          label: "Appointment Date",
          value: appointment.appointmentDate,
        },
        appointmentLocation: {
          label: "Appointment Location",
          value: appointment.locationName,
        },
        appointmentTime: {
          label: "Appointment Time",
          value: appointment.appointmentTime,
        },
      }
    );

    if (response.statusText === "OK") {
      setLoading(false);
      updateState({ uuid: "qr-code", answer: patientInfo.uuid.value });
    }

    // console.log("response", response);
  }

  return (
    <form className="border p-4" onSubmit={handleSubmit(onSubmit)}>
      {!appointment.locationName ? (
        <div className="p-4 border bg-light">
          <div className="row">
            <div className="col-12 col-lg-6">
              <FirestoreCollection path="Appointment Slots">
                {(slotsData) => {
                  const slots = slotsData.value;
                  // !slotsData.isLoading && console.log("slot data", slotsData);
                  return (
                    <FirestoreCollection path="Appointment Locations">
                      {(locationsData) => {
                        const locations = locationsData.value;
                        // !locationsData.isLoading &&
                        // console.log(locations, typeof locations);
                        return locationsData.isLoading ? (
                          "Loading locations..."
                        ) : (
                          <>
                            <p className="h5">Select a location:</p>
                            {locations.map((location, index) => {
                              // console.log("location dates", location.dates);
                              return (
                                <details key={index}>
                                  <summary className="d-flex w-100">
                                    <div className="w-100 border p-4 d-flex align-items-center">
                                      <div className="mr-4">
                                        <FA name="hospital-o" size="2x" />
                                      </div>
                                      <div>
                                        <p className="mb-1">
                                          <strong>{location.name}</strong>
                                        </p>

                                        <p className="mb-0">
                                          {location.address}
                                        </p>
                                      </div>
                                    </div>
                                  </summary>

                                  <div className="py-2">
                                    <p className="mb-2">
                                      <strong>Select day:</strong>
                                    </p>
                                    <ul className="list-group ml-0">
                                      {location.dates.map((date, index) => {
                                        const dateSlots = slots.filter(
                                          (slot) => slot.date === date
                                        )[0];
                                        console.log(
                                          "date slots",
                                          dateSlots.slots
                                        );
                                        return (
                                          <details key={index}>
                                            <summary className="d-flex">
                                              <div className="list-group-item list-group-item-action">
                                                <FA name="calendar" /> {date}
                                              </div>
                                            </summary>

                                            <div className="p-2">
                                              {map(
                                                dateSlots.slots,
                                                (value, key) => {
                                                  console.log(value, key);
                                                  const slotsAvailable =
                                                    30 - (value.length - 1);
                                                  const hasSlots =
                                                    slotsAvailable.length === 0;

                                                  return (
                                                    <div className="p-1 d-inline-block">
                                                      <button
                                                        type="button"
                                                        className="btn btn-primary"
                                                        disabled={hasSlots}
                                                        onClick={(event) => {
                                                          setApointment({
                                                            appointmentDate: date,
                                                            locationName:
                                                              location.name,
                                                            appointmentTime: key,
                                                          });
                                                        }}
                                                      >
                                                        {key}{" "}
                                                        <span className="ml-2 badge badge-pill badge-light rounded">
                                                          {slotsAvailable}
                                                        </span>
                                                      </button>
                                                    </div>
                                                  );
                                                }
                                              )}
                                            </div>
                                          </details>
                                        );
                                      })}
                                    </ul>
                                  </div>
                                </details>
                              );
                            })}
                          </>
                        );
                      }}
                    </FirestoreCollection>
                  );
                }}
              </FirestoreCollection>
            </div>
          </div>
        </div>
      ) : (
        <>
          <div className="p-4 border bg-light mb-4">
            <div className="row mb-4">
              <div className="col-12 col-lg-6">
                <label htmlFor="race">
                  Preferred Language<span className="text-danger">*</span>
                </label>
                <select
                  className={`custom-select ${
                    errors && errors.race && "is-invalid"
                  }`}
                  name="preferredLanguage"
                  id="preferredLanguage"
                  onChange={handleChange}
                  ref={register({ required: true })}
                  defaultValue=""
                >
                  <option value="" disabled hidden>
                    Select One:
                  </option>
                  <option value="English">English</option>
                  <option value="Spanish">Spanish</option>
                </select>
                {errors && errors.race && (
                  <div className="invalid-feedback">
                    Preferred Language is required
                  </div>
                )}
              </div>
            </div>
          </div>

          <div className="row mb-4">
            <div className="col">
              <Input
                stateObj={patientInfo}
                fieldKey="firstName"
                handleChange={handleChange}
                register={register}
                errors={errors}
                required
              />
            </div>
            <div className="col">
              <Input
                stateObj={patientInfo}
                fieldKey="middleName"
                handleChange={handleChange}
              />
            </div>

            <div className="col">
              <Input
                stateObj={patientInfo}
                fieldKey="lastName"
                handleChange={handleChange}
                register={register}
                errors={errors}
                required
              />
            </div>
          </div>
          <div className="row mb-4">
            <div className="col">
              <Input
                stateObj={patientInfo}
                fieldKey="dateOfBirth"
                handleChange={handleChange}
                register={register}
                errors={errors}
                required
              />
            </div>

            <div className="col">
              <Input
                stateObj={patientInfo}
                fieldKey="mothersFirstName"
                handleChange={handleChange}
                register={register}
                errors={errors}
                required
              />
            </div>

            <div className="col">
              <Input
                stateObj={patientInfo}
                fieldKey="mothersMaidenName"
                handleChange={handleChange}
                register={register}
                errors={errors}
                required
              />
            </div>
          </div>
          <div className="row mb-4">
            <div className="col">
              <label htmlFor="race">
                Race <span className="text-danger">*</span>
              </label>
              <select
                className={`custom-select ${
                  errors && errors.race && "is-invalid"
                }`}
                name="race"
                id="race"
                onChange={handleChange}
                ref={register({ required: true })}
                defaultValue=""
              >
                <option value="" disabled hidden />
                <option value="American Indian / Alaska native">
                  American Indian / Alaska native
                </option>
                <option value="Asian">Asian</option>
                <option value="Black">Black</option>
                <option value="Other">Other</option>
                <option value="Pacific Islander / Hawaiian">
                  Pacific Islander / Hawaiian
                </option>
                <option value="White / Caucasian">White / Caucasian</option>
              </select>
              {errors && errors.race && (
                <div className="invalid-feedback">Race is required</div>
              )}
            </div>

            <div className="col">
              <label htmlFor="ethnicity">
                Ethnicity <span className="text-danger">*</span>
              </label>
              <select
                className={`custom-select ${
                  errors && errors.ethnicity && "is-invalid"
                }`}
                name="ethnicity"
                id="ethnicity"
                onChange={handleChange}
                ref={register({ required: true })}
                defaultValue=""
              >
                <option value="" disabled hidden />
                <option value="Hispanic or Latino">Hispanic or Latino</option>
                <option value="Non-Hispanic or Latino">
                  Non-Hispanic or Latino
                </option>
              </select>
              {errors && errors.ethnicity && (
                <div className="invalid-feedback">Ethnicity is required</div>
              )}
            </div>
          </div>
          <FormHeading>Contact Information</FormHeading>
          <div className="row mb-4">
            <div className="col">
              <Input
                stateObj={patientInfo}
                fieldKey="primaryPhoneNumber"
                handleChange={handleChange}
                type="tel"
                register={register}
                errors={errors}
                required
              />
            </div>

            <div className="col">
              <Input
                stateObj={patientInfo}
                fieldKey="mobilePhoneNumber"
                handleChange={handleChange}
                type="tel"
                register={register}
                errors={errors}
                required
              />
            </div>

            <div className="col">
              <Input
                stateObj={patientInfo}
                fieldKey="emailAddress"
                handleChange={handleChange}
                type="email"
                register={register}
                errors={errors}
                required
              />
            </div>
          </div>
          <div className="row mb-4">
            <div className="col">
              <Input
                stateObj={patientInfo}
                fieldKey="streetAddress"
                handleChange={handleChange}
                register={register}
                errors={errors}
                required
              />
            </div>
          </div>
          <div className="row mb-4">
            <div className="col">
              <div className="row">
                <div className="col-8">
                  <Input
                    stateObj={patientInfo}
                    fieldKey="city"
                    handleChange={handleChange}
                    register={register}
                    errors={errors}
                    required
                  />
                </div>

                <div className="col-4">
                  <Input
                    stateObj={patientInfo}
                    fieldKey="state"
                    handleChange={handleChange}
                    register={register}
                    errors={errors}
                    required
                  />
                </div>
              </div>
            </div>

            <div className="col">
              <div className="row">
                <div className="col-8">
                  <Input
                    stateObj={patientInfo}
                    fieldKey="country"
                    handleChange={handleChange}
                    register={register}
                    errors={errors}
                    required
                  />
                </div>

                <div className="col-4">
                  <Input
                    stateObj={patientInfo}
                    fieldKey="zip"
                    handleChange={handleChange}
                    register={register}
                    errors={errors}
                    required
                  />
                </div>
              </div>
            </div>
          </div>
          <FormHeading>Emergency Contact Information</FormHeading>
          <div className="row mb-4">
            <div className="col">
              <div className="row">
                <div className="col">
                  <Input
                    stateObj={patientInfo}
                    fieldKey="emergencyContactName"
                    handleChange={handleChange}
                    register={register}
                    errors={errors}
                    required
                  />
                </div>

                <div className="col">
                  <Input
                    stateObj={patientInfo}
                    fieldKey="emergencyContactRelationship"
                    handleChange={handleChange}
                    register={register}
                    errors={errors}
                    required
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="row mb-4">
            <div className="col">
              <div className="row">
                <div className="col">
                  <Input
                    stateObj={patientInfo}
                    fieldKey="emergencyContactPhone"
                    handleChange={handleChange}
                    register={register}
                    errors={errors}
                    required
                  />
                </div>

                <div className="col">
                  <Input
                    stateObj={patientInfo}
                    fieldKey="emergencyContactMobile"
                    handleChange={handleChange}
                    register={register}
                    errors={errors}
                    required
                  />
                </div>
              </div>
            </div>
          </div>
          <FormHeading>Insurance Information</FormHeading>
          <div className="row mb-4">
            <div className="col">
              <Input
                stateObj={patientInfo}
                fieldKey="insuranceCompany"
                handleChange={handleChange}
              />
            </div>
            <div className="col">
              <Input
                stateObj={patientInfo}
                fieldKey="insuranceGroupId"
                handleChange={handleChange}
              />
            </div>
            <div className="col">
              <Input
                stateObj={patientInfo}
                fieldKey="insurancePhoneNumber"
                handleChange={handleChange}
              />
            </div>

            <div className="col">
              <Input
                stateObj={patientInfo}
                fieldKey="insuranceAddress"
                handleChange={handleChange}
              />
            </div>
          </div>

          <div className="row mb-4">
            <div className="col">
              <Input
                stateObj={patientInfo}
                fieldKey="insuranceMemberId"
                handleChange={handleChange}
              />
            </div>
            <div className="col">
              <Input
                stateObj={patientInfo}
                fieldKey="insuranceSubscriberName"
                handleChange={handleChange}
              />
            </div>
            <div className="col">
              <Input
                stateObj={patientInfo}
                fieldKey="insuranceSubscriberDOB"
                handleChange={handleChange}
              />
            </div>
          </div>
          <div className="row mb-4">
            <div className="col">
              <div className="form-check mb-2 d-flex">
                <Input
                  stateObj={patientInfo}
                  fieldKey="uninsured"
                  handleChange={handleChange}
                  type="checkbox"
                />
              </div>
            </div>
          </div>
          {false && (
            <>
              <FormHeading>
                Medical Conditions{" "}
                <small>
                  (Check all that apply. Responses are voluntary and will not
                  prevent you from receiving the vaccine.)
                </small>
              </FormHeading>
              <div className="row mb-4">
                <div className="col">
                  <div className="form-check mb-2 d-flex">
                    <Input
                      stateObj={patientInfo}
                      fieldKey="asthma"
                      handleChange={handleChange}
                      type="checkbox"
                    />
                  </div>

                  <div className="form-check mb-2 d-flex">
                    <Input
                      stateObj={patientInfo}
                      fieldKey="cancer"
                      handleChange={handleChange}
                      type="checkbox"
                    />
                  </div>

                  <div className="form-check mb-2 d-flex">
                    <Input
                      stateObj={patientInfo}
                      fieldKey="chronicKidneyDisease"
                      handleChange={handleChange}
                      type="checkbox"
                    />
                  </div>

                  <div className="form-check mb-2 d-flex">
                    <Input
                      stateObj={patientInfo}
                      fieldKey="chronicObstructivePulmonaryDisease"
                      handleChange={handleChange}
                      type="checkbox"
                    />
                  </div>
                </div>

                <div className="col">
                  <div className="form-check mb-2 d-flex">
                    <Input
                      stateObj={patientInfo}
                      fieldKey="haveIBeenSickInTheLast30Days"
                      handleChange={handleChange}
                      type="checkbox"
                    />
                  </div>

                  <div className="form-check mb-2 d-flex">
                    <Input
                      stateObj={patientInfo}
                      fieldKey="highBloodPressure"
                      handleChange={handleChange}
                      type="checkbox"
                    />
                  </div>

                  <div className="form-check mb-2 d-flex">
                    <Input
                      stateObj={patientInfo}
                      fieldKey="immunocompromisedStateFromSolidOrganTransplant"
                      handleChange={handleChange}
                      type="checkbox"
                    />
                  </div>
                  <div className="form-check mb-2 d-flex">
                    <Input
                      stateObj={patientInfo}
                      fieldKey="obesity"
                      handleChange={handleChange}
                      type="checkbox"
                    />
                  </div>
                </div>
                <div className="col">
                  <div className="form-check mb-2 d-flex">
                    <Input
                      stateObj={patientInfo}
                      fieldKey="seriousHearthCondition"
                      handleChange={handleChange}
                      type="checkbox"
                    />
                  </div>

                  <div className="form-check mb-2 d-flex">
                    <Input
                      stateObj={patientInfo}
                      fieldKey="sickleCellDisease"
                      handleChange={handleChange}
                      type="checkbox"
                    />
                  </div>

                  <div className="form-check mb-2 d-flex">
                    <Input
                      stateObj={patientInfo}
                      fieldKey="smoking"
                      handleChange={handleChange}
                      type="checkbox"
                    />
                  </div>

                  <div className="form-check mb-2 d-flex">
                    <Input
                      stateObj={patientInfo}
                      fieldKey="type2Diabetes"
                      handleChange={handleChange}
                      type="checkbox"
                    />
                  </div>
                </div>
              </div>
              <FormHeading>
                Contraindications
                <br />
                <small>
                  If you have any of the conditions below, it is recommended
                  that you do not receive the COVID-19 vaccination without
                  speaking to your healthcare provider.
                </small>
                <br />
                <br />
                <small>
                  The manufacturer advises against administering the Moderna
                  COVID-19 Vaccine to individuals with known history of severe
                  allergic reaction (e.g., anaphylaxis) to any component of the
                  Moderna COVID-19 Vaccine.
                </small>
                <br />
                <br />
                <small>
                  Each dose of the Moderna COVID-19 Vaccine includes the
                  following ingredients: messenger ribonucleic acid (mRNA),
                  lipids (SM-102; polythylene glycol [PEG] 2000 dimyristoyl
                  glycerol [DMG]; cholesterol; and
                  1,2-distearoyl-sn-glycero-3-phosphocholine [DSPC]),
                  tromethamine, tromethamine hydrochloride, acetic acid, sodium
                  acetate, and sucrose.
                </small>
              </FormHeading>
              <div className="row mb-0">
                <div className="col">
                  <p>Check all that apply:</p>
                </div>
              </div>
              <div className="row mb-4">
                <div className="col">
                  <div className="form-check mb-2 d-flex">
                    <Input
                      stateObj={patientInfo}
                      fieldKey="historyOfAllergicReactionToList"
                      handleChange={handleChange}
                      type="checkbox"
                    />
                  </div>
                  <div className="form-check mb-2 d-flex">
                    <Input
                      stateObj={patientInfo}
                      fieldKey="historyOfSevereAllergicReactionAnaphylaxis"
                      handleChange={handleChange}
                      type="checkbox"
                    />
                  </div>
                  <div className="form-check mb-2 d-flex">
                    <Input
                      stateObj={patientInfo}
                      fieldKey="historyOfSevereAllergicReactionMedicine"
                      handleChange={handleChange}
                      type="checkbox"
                    />
                  </div>
                  <div className="form-check mb-2 d-flex">
                    <Input
                      stateObj={patientInfo}
                      fieldKey="planningToBecomePregnant"
                      handleChange={handleChange}
                      type="checkbox"
                    />
                  </div>
                  <div className="form-check mb-2 d-flex">
                    <Input
                      stateObj={patientInfo}
                      fieldKey="receivedVaccineWithin14Days"
                      handleChange={handleChange}
                      type="checkbox"
                    />
                  </div>
                  <div className="form-check mb-2 d-flex">
                    <Input
                      stateObj={patientInfo}
                      fieldKey="receivedCovidVaccineElsewhere"
                      handleChange={handleChange}
                      type="checkbox"
                    />
                  </div>
                </div>
              </div>
              <div className="row mb-4">
                <div className="col">
                  <div className="form-check mb-2 d-flex">
                    <Input
                      stateObj={patientInfo}
                      fieldKey="consent"
                      type="checkbox"
                      handleChange={handleChange}
                      register={register}
                      errors={errors}
                      required
                    />
                  </div>
                </div>
              </div>
            </>
          )}

          <div className="row mb-4">
            <div className="col">
              <p>
                <strong>COVID-19 Vaccination Consent</strong>
              </p>
              <p>
                I understand that the Moderna COVID-19 Vaccine has not been
                approved or licensed by the US Food and Drug Administration
                (FDA), but has been authorized for emergency use by FDA, under
                an Emergency Use Authorization (EUA), to prevent Coronavirus
                Disease 2019 (COVID-19) for use in individuals 18 years of age
                and older. I understand that there is no FDA-approved vaccine to
                prevent COVID-19.
              </p>

              <p>
                I understand that the information completed on this form is
                correct to the best of my knowledge and that if a vaccine is
                administered, Lone Star Family Health Center is required to
                enter vaccination information into the Texas Immunization
                Information System, ImmTrac2. Lone Star Family Health Center may
                also be required to provide vaccination information to other
                state and/or federal jurisdiction’s Immunization Information
                Systems or other designated systems. I understand that ImmTrac2
                may retain this information beyond the 5-year retention period.
              </p>

              <p>
                I acknowledge that I have been given all relevant information
                regarding the Moderna COVID-19 vaccine.
              </p>

              <p>
                I understand that the information completed on this form is
                correct to the best of my knowledge and that if a vaccine is
                administered, Lone Star Family Health Center is required to
                enter vaccination information into the Texas Immunization
                Information System, ImmTrac2. Lone Star Family Health Center may
                also be required to provide vaccination information to other
                state and/or federal jurisdiction’s Immunization Information
                Systems or other designated systems. I understand that ImmTrac2
                may retain this information beyond the 5-year retention period.
              </p>

              <p>
                I acknowledge that my provider, or his/her representative, has
                reviewed the relevant Moderna COVID-19 vaccine information with
                me and has answered all questions I may have regarding the
                vaccine.
              </p>

              <p>
                I understand that I must return to Lone Star Family Health
                Center to receive the second dose of the Moderna COVID-19
                vaccine within 24 to 28 days of my first dose in order to
                complete the vaccination schedule.
              </p>
            </div>
          </div>

          <div className="row mb-4">
            <div className="col">
              <p>
                <strong>
                  View Moderna COVID-19 Fact Sheet:{" "}
                  <a href="/Moderna_English.pdf" target="_blank">
                    English
                  </a>{" "}
                  |{" "}
                  <a href="/Moderna_Spanish.pdf" target="_blank">
                    Spanish
                  </a>
                </strong>
              </p>
            </div>
          </div>

          <div className="p-4 border bg-light mb-4">
            <div className="row mb-4">
              <div className="col">
                <div className="form-check mb-2 d-flex">
                  <Input
                    stateObj={patientInfo}
                    fieldKey="consentToReceiveVaccine"
                    type="checkbox"
                    handleChange={handleChange}
                    register={register}
                    errors={errors}
                    required
                  />
                </div>
              </div>
            </div>
            <div className="row mb-4">
              <div className="col">
                <div className="mb-2">
                  <Input
                    stateObj={patientInfo}
                    fieldKey="participant"
                    handleChange={handleChange}
                    register={register}
                    errors={errors}
                    required
                  />
                </div>
              </div>

              <div className="col">
                <div className="mb-2">
                  <Input
                    stateObj={patientInfo}
                    fieldKey="date"
                    type="date"
                    handleChange={handleChange}
                    register={register}
                    errors={errors}
                    required
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="row mb-4">
            <div className="col">
              <p>
                By clicking REQUEST APPOINTMENT you are choosing to send your
                information to Lone Star Family Health Center for administration
                of the COVID Vaccine.
                <p>
                  If you would like to decline, or do not agree to these terms,
                  please choose CANCEL.
                </p>
              </p>
            </div>
          </div>

          {!!Object.keys(errors).length && (
            <div className="row mb-4">
              <div className="col">
                <div className="form-text text-danger">
                  <FA name="exclamation-circle" /> The form contains errors
                  please fix them.
                </div>
              </div>
            </div>
          )}
          <div className="row">
            <div className="col-6">
              <div className="d-flex">
                <Button type="submit" disabled={!!Object.keys(errors).length}>
                  {isLoading && (
                    <>
                      <FA name="spinner" spin />{" "}
                    </>
                  )}
                  Request Appointment
                </Button>
                <button
                  className="btn btn-danger text-uppercase ml-4"
                  onClick={() => {
                    updateState({ uuid: "welcome", answer: null });
                  }}
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </>
      )}
    </form>
  );
}

export { PatientInformationForm };
