import React from "react";

const questions = [
  {
    uuid: "eventbrite",
    content: () => {},
    type: "eventbrite",
    answer: null,
    // current: true,
    current: false,
    options: {},
  },
  {
    uuid: "qr-code",
    content: () => {},
    type: "qr-code",
    answer: null,
    current: false,
    options: {
      data: {},
    },
  },
  {
    uuid: "welcome",
    content: () => (
      <div className="text-left">
        <p className="lead">
          <strong>
            Welcome to the Lone Star Family Health Center COVID-19 Vaccination
            Scheduler Application.
          </strong>
        </p>

        <p className="lead">
          As we receive COVID-19 vaccine allocations, Lone Star Family Health
          Center in partnership with Montgomery County and the Office of
          Homeland Security and Emergency Management, will be providing COVID-19
          vaccinations based on the{" "}
          <a href="https://dshs.texas.gov/coronavirus/immunize/vaccine.aspx">
            current phase(s)
          </a>{" "}
          published by the Texas Department of State Health Services. This site
          will be used to determine your eligibility as well as schedule your
          vaccine appointment.
        </p>

        <p className="lead">
          Currently, we are scheduling appointments to receive dose #1 of the
          Moderna COVID-19 vaccine for the following individuals:
        </p>

        <ul>
          <li>
            <strong>Phase 1A</strong>: Health Care Workers – Hospital, Long-term
            care, EMS 9-1-1, Home Health, Outpatient, ER/Urgent Care,
            Pharmacies, Last Responders, School Nurses
          </li>

          <li>
            <strong>Phase 1A</strong>: Residents of Long-Term Care Facilities
          </li>
          <li>
            <strong>Phase 1B</strong>: Persons 65 and older
          </li>
          <li>
            <strong>Phase 1B</strong>: Persons 18 and older with at least one
            chronic medical condition, including pregnancy
          </li>
        </ul>

        <p className="text-uppercase">
          <strong>Important Notes:</strong>
        </p>

        <ul>
          <li>
            The COVID-19 vaccination is a two-dose series. The Moderna vaccine
            requires two doses 28 days apart (or at least within 24 – 32 days of
            the first dose).
          </li>

          <li>
            The COVID-19 vaccines (Pfizer and Moderna) are not interchangeable.
            The second dose in each of the two-dose series must be of the same
            manufacturer/type as the first.
          </li>
        </ul>

        <p>
          <small>
            <strong>PLEASE NOTE</strong>: There are a very limited number of
            COVID-19 vaccine appointments available. You will be answering a
            series of questions to determine if you are eligible for the
            vaccine, but eligibility does not guarantee that you will receive a
            vaccine appointment. We will be working over the coming weeks to
            ramp up the number of available appointments. Thank you for your
            patience.{" "}
          </small>
        </p>
      </div>
    ),
    type: "welcome",
    answer: null,
    current: true,
    // current: false,
    options: {
      link: "healthcare-worker",
    },
  },
  {
    uuid: "patient-information",
    content: () => {},
    type: "form",
    answer: null,
    // current: true,
    current: false,
    options: {
      data: {},
    },
  },
  {
    uuid: "healthcare-worker",
    content: () => (
      <div>
        <div>
          <p className="lead">
            Are you a Health Care Worker eligible to receive a COVID-19 Vaccine
            under Phase 1A as defined by Texas Department of State Health
            Services (
            <a
              href="https://dshs.texas.gov/coronavirus/immunize/vaccine/EVAP-Phase1A.pdf"
              target="_blank"
              rel="noreferrer"
            >
              review Health Care Workers Definition from the Texas Department of
              State Health Services
            </a>
            ).
          </p>
        </div>
      </div>
    ),
    type: "yes-or-no",
    answer: null,
    current: false,
    // current: true,
    options: {
      yes: "allergic-reactions",
      no: "65-years",
    },
  },
  {
    uuid: "one-of-these-chronic-conditions",
    content: () => (
      <div>
        <p className="lead">
          Do you have one of these chronic medical conditions?
        </p>

        <ul>
          <li>Cancer</li>
          <li>Chronic kidney disease</li>
          <li>COPD (chronic obstructive pulmonary disease)</li>
          <li>Heart disease</li>
          <li>Solid organ transplantation</li>
          <li>Obesity (body mass index of 30 kg/m2 or higher)</li>
          <li>Pregnancy</li>
          <li>Sickle cell disease</li>
          <li>Diabetes</li>
        </ul>
      </div>
    ),
    type: "yes-or-no",
    answer: null,
    current: false,
    options: {
      yes: "allergic-reactions",
      no: "only-phase-1a",
    },
  },
  {
    uuid: "18-years",
    content: () => (
      <div>
        <p className="lead">Are you 18 years of age or older?</p>
      </div>
    ),
    type: "yes-or-no",
    answer: null,
    current: false,
    options: {
      yes: "one-of-these-chronic-conditions",
      no: "over-18-years",
    },
  },
  {
    uuid: "over-18-years",
    content: () => (
      <div>
        <p className="lead">
          The vaccine Lone Star is providing is only recommended for individuals
          over the age of 18.
        </p>
      </div>
    ),
    type: "link",
    answer: null,
    current: false,
    options: {
      link: "healthcare-worker",
    },
  },
  {
    uuid: "65-years",
    content: () => (
      <div>
        <p className="lead">Are you 65 years of age or older?</p>
      </div>
    ),
    type: "yes-or-no",
    answer: null,
    current: false,
    options: {
      yes: "allergic-reactions",
      no: "18-years",
    },
  },
  {
    uuid: "only-phase-1a-first-dose",
    content: () => (
      <div>
        <p className="lead">
          We are currently only providing first dose vaccinations for people in
          Phase 1A and 1B.
        </p>
        <p>
          Please reach out to the provider who administered your first dose.
        </p>

        <p>
          If you received the first dose at Lone Star Family Health Center,
          please contact us at{" "}
          <a href="mailto:immunizations@lonestarfamily.org">
            immunizations@lonestarfamily.org
          </a>
          .
        </p>
      </div>
    ),
    type: "link",
    answer: null,
    current: false,
    options: {
      link: "healthcare-worker",
    },
  },
  {
    uuid: "only-phase-1a",
    content: () => (
      <div>
        <p className="lead">
          We are currently only providing vaccinations for people in Phase 1A
          and 1B. Please schedule your appointment when you are eligible to
          receive the vaccination.
        </p>
      </div>
    ),
    type: "link",
    answer: null,
    current: false,
    options: {
      link: "healthcare-worker",
    },
  },
  {
    uuid: "return-home",
    content: () => (
      <div>
        <p>
          Please contact Lone Star Family Health Center at{" "}
          <a href="mailto:immunizations@lonestarfamily.org">
            immunizations@lonestarfamily.org
          </a>
          .
        </p>

        <p>
          We will contact you to discuss receiving the vaccine at our Conroe
          Clinic.
        </p>
      </div>
    ),
    type: "link",
    answer: null,
    current: false,
    options: {
      link: "healthcare-worker",
    },
  },
  {
    uuid: "allergic-reactions",
    content: () => (
      <div>
        <p className="lead">
          Do you have a history of severe allergic reactions (e.g.,
          anaphylaxis*), including from any prior injectable medications or
          vaccines?
        </p>
        <p className="lead">
          *Anaphylaxis is a severe allergic reaction that leads to wheezing,
          chest tightness, difficulty breathing, rash, swelling in throat and
          lowered blood pressure that starts between 5-30 minutes after contact
          with an allergen
        </p>
      </div>
    ),
    type: "yes-or-no",
    answer: null,
    current: false,
    options: {
      yes: "return-home",
      no: "other-vaccine",
    },
  },
  {
    uuid: "other-vaccine",
    content: () => (
      <div>
        <p className="lead">
          Have you received any other vaccine within the past two weeks?
        </p>
      </div>
    ),
    type: "yes-or-no",
    answer: null,
    current: false,
    options: {
      yes: "min-14-days",
      no: "pregnant",
    },
  },
  {
    uuid: "min-14-days",
    content: () => (
      <div>
        There should be a minimum interval of 14 days before or after
        administration with any other vaccines. Consult with your physician
        prior to vaccination if you have additional questions.
      </div>
    ),
    type: "link",
    answer: null,
    current: false,
    options: {
      link: "healthcare-worker",
    },
  },
  {
    uuid: "pregnant",
    content: () => (
      <div>
        <p className="lead">
          Are you pregnant, breastfeeding, or immunocompromised?
        </p>
      </div>
    ),
    type: "yes-or-no",
    answer: null,
    current: false,
    options: {
      yes: "confirm-pregnant",
      no: "exposed-covid",
    },
  },
  {
    uuid: "confirm-pregnant",
    content: () => (
      <div>
        <p className="lead">
          If you are pregnant, breastfeeding, or immunocompromised and are part
          of a group that is recommended to receive COVID-19 vaccine, you may
          choose to be vaccinated. You may want to consult with your physician,
          but it is not required prior to vaccination.
        </p>

        <p className="lead">
          Do you still want to be vaccinated with an understanding there are
          currently no available data on the safety or effectiveness of COVID-19
          vaccines, including Moderna COVID-19 vaccine, in pregnant people,
          lactating people, or immunocompromised people.
        </p>

        <p className="lead">
          *If you are pregnant and receive the vaccine, please register with
          Moderna's Pregnancy Registry at{" "}
          <a href="tel:18666633762">1-866-MODERNA (663-3762)</a>.
        </p>
      </div>
    ),
    type: "yes-or-no",
    answer: null,
    current: false,
    options: {
      yes: "exposed-covid",
      no: "not-eligible",
    },
  },
  {
    uuid: "not-eligible",
    content: () => (
      <div>
        <p className="lead">
          Thank you for your interest in receiving the vaccine.
        </p>

        <p className="lead">
          Please check back when you are ready to schedule your appointment.
        </p>
      </div>
    ),
    type: "link",
    answer: null,
    current: false,
    options: {
      link: "healthcare-worker",
    },
  },
  {
    uuid: "exposed-covid",
    content: () => (
      <div>
        <p className="lead">
          Have you recently been exposed to a person with COVID-19 within the
          last 14 days, or are you currently under quarantine for exposure?
        </p>
      </div>
    ),
    type: "yes-or-no",
    answer: null,
    current: false,
    options: {
      yes: "14-day-quarantine",
      no: "antibody-therapy",
    },
  },
  {
    uuid: "14-day-quarantine",
    content: () => (
      <div>
        {" "}
        Vaccine should be deferred until a minimum 14-day quarantine period is
        completed.{" "}
      </div>
    ),
    type: "link",
    answer: null,
    current: false,
    options: {
      link: "healthcare-worker",
    },
  },

  {
    uuid: "antibody-therapy",
    content: () => (
      <div>
        <p className="lead">
          Have you received monoclonal antibody therapy or convalescent plasma
          for COVID-19 in the previous 90 days?
        </p>
      </div>
    ),
    type: "yes-or-no",
    answer: null,
    current: false,
    options: {
      yes: "90-days-monoclonal",
      no: "tested-positive-covid",
    },
  },
  {
    uuid: "90-days-monoclonal",
    content: () => (
      <div>
        Vaccine should be deferred until at least after 90 days from receiving
        monoclonal antibody therapy or convalescent plasma.
      </div>
    ),
    type: "link",
    answer: null,
    current: false,
    options: {
      link: "healthcare-worker",
    },
  },
  {
    uuid: "tested-positive-covid",
    content: () => (
      <div>
        <p className="lead">
          Have you previously tested positive for COVID-19?
        </p>
      </div>
    ),
    type: "yes-or-no",
    answer: null,
    current: false,
    options: {
      yes: "vaccine-should-be-referred",
      no: "first-second-dose",
    },
  },
  {
    uuid: "vaccine-should-be-referred",
    content: () => (
      <div>
        <p className="lead">
          Vaccine should be deferred until you have recovered and are no longer
          contagious and in isolation. Isolation period ends 14 days after onset
          of symptoms (or date of test if no symptoms) with improvement of
          symptoms and fever free for at least 24 hours.
        </p>

        <p className="lead">Have you completed your isolation period?</p>
      </div>
    ),
    type: "yes-or-no",
    answer: null,
    current: false,
    options: {
      yes: "first-second-dose",
      no: "14-day-quarantine",
    },
  },
  {
    uuid: "first-second-dose",
    content: () => (
      <div>
        <p className="lead">Is this your first or second dose?</p>
      </div>
    ),
    type: "first-second-dose",
    answer: null,
    current: false,
    options: {
      first: "eventbrite",
      // first: "patient-information",
      second: "only-phase-1a-first-dose",
      // moderna: "patient-information",
      // pfizer: "patient-information",
    },
  },
];

export { questions };
