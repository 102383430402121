import React, { useState, useEffect } from "react";
import dashify from "dashify";

/**
 * @param {object} props
 * @returns {JSX.Element}
 * @constructor
 */
function Input({
  stateObj,
  fieldKey,
  type = "text",
  handleChange,
  errors,
  register,
  required,
}) {
  const { label, value } = stateObj[fieldKey];

  console.log("type of register in Input", typeof register);

  const getClasses = (type) => {
    switch (type) {
      case "checkbox":
        return "form-check-input";
      default:
        return "form-control";
    }
  };

  return (
    <>
      <label htmlFor={fieldKey}>
        {label} {required && <span className="text-danger">*</span>}
      </label>
      <input
        className={`${getClasses(type)} ${
          errors && errors[fieldKey] && "is-invalid"
        }`}
        type={type}
        id={fieldKey}
        name={fieldKey}
        onChange={handleChange}
        checked={value}
        value={value}
        ref={required && register({ required })}
      />
      {errors && type !== "checkbox" && (
        <div className="invalid-feedback">{label} is required</div>
      )}
      {errors && type === "checkbox" && (
        <>
          <br />
          <div className="invalid-feedback">Checkbox is required</div>
        </>
      )}
    </>
  );
}

export { Input };
