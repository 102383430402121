import firebase from "firebase";
import { config } from "./config";

require("firebase/firestore");

firebase.initializeApp({
  ...config,
});

const db = firebase.firestore();

export { firebase, db };
