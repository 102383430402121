const fieldNames = {
  personalInformation: {
    uuid: "",
    preferredLanguage: "Preferred Language",
    firstName: "First Name",
    middleName: "MiddleName",
    lastName: "Last Name",
    dateOfBirth: "Date of Birth",
    mothersFirstName: "Mother's First Name",
    mothersMaidenName: "Mother's Maiden Name",
    race: "Race",
    ethnicity: "Ethnicity",
    consent: "I certify that I have read and accept the Privacy Policy",
    consentToReceiveVaccine:
      "I consent to receive the Moderna COVID-19 vaccine.",
    participant: "Participant",
    date: "Date",
  },
  contactInformation: {
    primaryPhoneNumber: "Primary Phone Number",
    mobilePhoneNumber: "Mobile Phone Number",
    emailAddress: "Email Address",
    streetAddress: "Street Address",
    city: "City",
    state: "State",
    country: "Country",
    zip: "Zip",
  },
  emergencyContact: {
    emergencyContactName: "Emergency Contact Name",
    emergencyContactRelationship: "Emergency Contact Relationship",
    emergencyContactPhone: "Emergency Contact Primary Phone Number",
    emergencyContactMobile: "Emergency Contact Mobile Phone Number",
  },
  insuranceInformation: {
    insuranceCompany: "Insurance Company",
    insuranceGroupId: "Group ID",
    insurancePhoneNumber: "Insurance Phone Number",
    insuranceAddress: "Insurance Address",
    insuranceMemberId: "Member ID",
    insuranceSubscriberName: "Subscriber Name",
    insuranceSubscriberDOB: "Subscriber DOB",
    uninsured: "Uninsured",
  },
  medicalConditions: {
    asthma: "Asthma",
    cancer: "Cancer",
    chronicKidneyDisease: "Chronic Kidney Disease",
    chronicObstructivePulmonaryDisease:
      "COPD (Chronic Obstructive Pulmonary Disease)",
    haveIBeenSickInTheLast30Days: "I have been sick in the last 30 days",
    highBloodPressure: "High Blood Pressure",
    immunocompromisedStateFromSolidOrganTransplant:
      "Immunocompromised state from solid organ transplant",
    obesity: "Obesity (BMI 30 or higher)",
    seriousHearthCondition: "Serious heart condition",
    sickleCellDisease: "Sickle Cell Disease",
    smoking: "Smoking",
    type2Diabetes: "Type 2 Diabetes",
  },
  contraindications: {
    historyOfAllergicReactionToList:
      "I have a history of allergic reaction to any of the ingredients listed above.",
    historyOfSevereAllergicReactionAnaphylaxis:
      "I have a history of severe allergic reactions, such as an immediate-onset anaphylaxis to a vaccine.",
    historyOfSevereAllergicReactionMedicine:
      "I have a history of severe allergic reactions, such as an immediate-onset anaphylaxis to medicine or food.",
    planningToBecomePregnant:
      "I am planning to become pregnant, or I am pregnant or breast feeding.",
    receivedVaccineWithin14Days:
      "I have received any vaccine (for example: pneumococcal, flu, etc.) within the last 14 days.",
    receivedCovidVaccineElsewhere:
      "I have received a COVID-19 vaccine elsewhere.",
  },
};

const {
  personalInformation,
  contactInformation,
  emergencyContact,
  insuranceInformation,
  // medicalConditions,
  // contraindications,
} = fieldNames;

const personalInfoObj = generateState(personalInformation);
const contactInfoObj = generateState(contactInformation);
const emergencyContactObj = generateState(emergencyContact);
// const medicalConditionsObj = generateState(medicalConditions);
// const contraindicationsObj = generateState(contraindications);
const insuranceInfoObj = generateState(insuranceInformation);

const stateObj = {
  ...personalInfoObj,
  ...contactInfoObj,
  ...insuranceInfoObj,
  ...emergencyContactObj,
  // ...medicalConditionsObj,
  // ...contraindicationsObj,
};

function generateState(obj) {
  let resultObj = {};

  Object.keys(obj).forEach((value, key) => {
    resultObj[value] = { value: "", label: obj[value] };
  });

  return resultObj;
}

export { fieldNames, stateObj };
