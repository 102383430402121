import React, { useState } from "react";
import { questions } from "./options";
import {
  YesOrNo,
  Checkbox,
  Link,
  PatientInformationForm,
  QR,
  FirstOrSecondDose,
  Welcome,
  EventBriteEmbed,
} from "./fields";

function Questions({ questions, updateState }) {
  const activeQuestionsArr = questions.filter((question) => question.current);
  return (
    <>
      {activeQuestionsArr.map((question, index) => {
        switch (question.type) {
          case "eventbrite":
            return <EventBriteEmbed />;
          case "welcome":
            return (
              <Welcome key={index} updateState={updateState}>
                {question.content()}
              </Welcome>
            );
          case "first-second-dose":
            return (
              <FirstOrSecondDose
                key={index}
                updateState={updateState}
                questionObj={question}
              >
                {question.content()}
              </FirstOrSecondDose>
            );
          case "qr-code":
            return <QR key={index} questionObj={question} />;
          case "form":
            return (
              <PatientInformationForm key={index} updateState={updateState} />
            );
          case "yes-or-no":
            return (
              <YesOrNo
                key={index}
                updateState={updateState}
                questionObj={question}
              >
                {question.content()}
              </YesOrNo>
            );
          case "link":
            return (
              <Link key={index} updateState={updateState}>
                {question.content()}
              </Link>
            );
          case "checkbox":
            return (
              <Checkbox
                key={index}
                uuid={question.uuid}
                label={question.content}
                updateState={updateState}
              />
            );
          default:
            return null;
        }
      })}
    </>
  );
}

function QuestionnaireForm() {
  const [questionsState, answerQuestions] = useState(questions);

  const updateState = ({ uuid, answer }) => {
    // 1. extract question we need to update
    const questionToAnswer = questionsState.filter((question) => {
      return question.uuid === uuid;
    })[0];

    // 2. update question
    const answeredQuestion = { ...questionToAnswer, answer, current: true };

    // .3 form new state
    const newState = questionsState.map((question) => {
      if (question.uuid === uuid) {
        return { ...answeredQuestion };
      }

      return { ...question, current: false };
    });

    // 5. update state
    answerQuestions(newState);
  };

  return (
    <div className="mt-4 d-flex flex-column w-100">
      <Questions questions={questionsState} updateState={updateState} />
    </div>
  );
}

export { QuestionnaireForm };
